import { graphql } from 'gatsby'
import React from 'react'

import animation1 from '../../assets/blog/19/1.gif'
import animation2 from '../../assets/blog/19/2.gif'
import mobileImg from '../../assets/blog/19/3.png'
import heroImg from '../../assets/blog/19/hero.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Proč potřebujeme systém?" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Proč potřebujeme systém?" />
        <ContentSubPage>
          <div className="w-3/4 mx-auto my-12">
            <img
              src={heroImg}
              alt="Proč potřebujeme systém?"
              className="object-cover w-full max-h-[600px] rounded-[20px]"
            />
          </div>
          <p>
            Všichni to známe: ztracený lístek s nákupním seznamem, samolepicí
            papírky padající z monitoru, diáře, které běda ztratit, organizační
            aplikace, co leží na hřbitově ikonek ve vašem mobilu. Už jsme
            unavení z pokusů o systematické vedení úkolů, poznámek, tipů,
            termínů a všeho toho, co potřebujeme nějak držet v patrnosti. Žádný
            způsob není dokonalý, od většiny dříve či později utíkáme. A cítíme
            se jako chaotici, co si nedokáží zorganizovat život. Co když to ale
            není vaše chyba?
          </p>
          <p>
            Ve skutečnosti jde právě o projev naší potřeby mít v životě systém.
            Problém je ale právě v nedokonalých možnostech a aplikacích, které
            se neumí přizpůsobit každému aspektu našich docela komplikovaných
            dnů. Přesto není dobré házet elektronické aplikace hned přes palubu.
            Jde spíše o to najít tu pravou. Tedy takovou, která nám dovolí víc
            než jen zapisovat si termíny do kalendáře.
          </p>
          <p>
            Flagis nabízí vedle funkce úkolovníku mnoho dalších možností.
            Funguje také jako komunikační nástroj, je možné si v něm ukládat
            fotografie či wordové soubory, jeho výhodou je možnost organizovat
            si jednotlivé položky do nejrůznějších systémů a skupin. A v
            neposlední řadě plně nahradí právě poznámkové bloky, jak papírové,
            tak elektronické.
          </p>
          <p>
            Znáte to: máte večer zrovna čas a chuť podívat se na dobrý film, ale
            je těžké si zrovna vzpomenout, co že jste si to kdy slibovali, že se
            jistě podíváte. Seznam tipů na filmy, knihy, nebo třeba památky se v
            takové chvíli hodí. Stejně jako se denně hodí sbírka receptů, kterou
            lze navíc roztřídit podle národních kuchyní nebo třeba ingrediencí.
          </p>
          <p>
            Kdy byly děti naposledy na preventivních kontrolách? Co jim kdysi
            pomohlo s ošklivým nachlazením? Co mi kdy dělali v servisu s autem?
            Jaký dárek komu z rodiny a kamarádů narychlo vybrat, když mám k
            nákupům příležitost? Kdy a jaké očkování absolvovali domácí
            mazlíčci? A co teprve možnost vést si záznamy o nesmrtelných
            výrocích našich malých dětí či vnoučat, o jejich pokrocích a
            úspěších?
          </p>
          <p>
            Vyvolává ve vás takový seznam otázek úzkost či pocity viny? Nemusí,
            odpovědi a informace mohou být s Flagisem hned po ruce.
          </p>

          <div className="my-5">
            <a href={animation1} target="_blank" rel="noreferrer">
              <img
                src={animation1}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={animation2} target="_blank" rel="noreferrer">
              <img
                src={animation2}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={mobileImg} target="_blank" rel="noreferrer">
              <img
                src={mobileImg}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
